<ng-container [ngSwitch]="appearance">

  <!-- LINE -->
  <ng-container *ngSwitchCase="'line'">
    <div class="w-2 h-14" [style.background-color]="color">
    </div>
  </ng-container>

  <!-- CHIP -->
  <ng-container *ngSwitchCase="'chip'">
    <div class="rounded-full inline-block h-8" [style.background-color]="color">
      <div class="px-3 h-full flex items-center justify-center text-white uppercase font-bold">
        {{ statusName | translate }}
      </div>
    </div>
  </ng-container>

</ng-container>