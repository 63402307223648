import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilterHeaderComponent as EntityFilterHeaderComponent } from './entity-filter-header.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    EntityFilterHeaderComponent,
  ],
  exports: [EntityFilterHeaderComponent]
})
export class EntityFilterHeaderModule { }
