import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@app/shared/base/components/base-component';
import {
  ConfigurableTableColumn,
  ConfigurableTableDialogParameters,
} from '@app/shared/base/models/configurable-table-settings';
import { __ } from '@app/shared/functions/object.functions';


@Component({
  selector: 'kb-configurable-table-dialog',
  templateUrl: './configurable-table-dialog.component.html',
  styleUrls: ['./configurable-table-dialog.component.scss']
})
export class ConfigurableTableDialogComponent extends BaseComponent implements OnInit {

  columns: ConfigurableTableColumn[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigurableTableDialogParameters,
    public dialogRef: MatDialogRef<ConfigurableTableDialogComponent>
  ) {
    super();

    const localStorageSettings = JSON.parse(localStorage.getItem(this.data.localStorageKey)) as ConfigurableTableColumn[];

    if (__.IsNullOrUndefined(localStorageSettings)) {
      // There are no existing settings
      const displayedColumns = this.data.defaultSettings.map((q: any) => {
        q.visibility = true;
        return q;
      });

      // If the select column should be displayed and it does not exist in the displayed columns
      if (this.data.showSelectColumn !== false && displayedColumns.findIndex(q => q.key === 'select') === -1) {
        displayedColumns.unshift({ key: 'select', value: 'select', visibility: true });
      }

      // Set the GUI
      this.columns = displayedColumns;

      return;
    }

    // There are existing settings, so merge them with default settings
    const newColumns = this.data.defaultSettings.filter(q => !localStorageSettings.some(a => a.key === q.key));
    const removedColumns = localStorageSettings.filter(q => !this.data.defaultSettings.some(a => a.key === q.key));
    const existingColumns = localStorageSettings.filter(q => this.data.defaultSettings.some(a => a.key === q.key));

    const displayedColumns = [...existingColumns, ...newColumns.map((q: any) => {
      q.visibility = true;
      return q;
    })];

    // If the select column should be displayed and it does not exist in the displayed columns
    if (this.data.showSelectColumn !== false && displayedColumns.findIndex(q => q.key === 'select') === -1) {
      displayedColumns.unshift({ key: 'select', value: 'select', visibility: true });
    }

    // Set the GUI
    this.columns = displayedColumns;
  }

  ngOnInit() { }

  setToDefault() {
    const newColumns = __.Extend(this.data.defaultSettings);
    if (this.data.showSelectColumn === true) {
      const selectIndex = newColumns.findIndex((q: any) => q.key === 'select');
      if (selectIndex > -1) {
        newColumns.splice(selectIndex, 1);
      }
    }
    this.columns = newColumns;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  changeVisibility(column: ConfigurableTableColumn) {
    column.visibility = !column.visibility;
  }

  closeDialog(columns: any) {
    this.dialogRef.close(columns);
  }
}
