import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/services';
import { FileContainerBase } from '../components/files/models/FileContainer';
import { FileState } from '../components/files/models/FileState.enum';
import { __ } from '../functions/object.functions';
import { DatabaseFile } from '../models/classes/File';
import { Response } from './local/Response';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Injectable()
export class FilesService extends BaseService {

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  downloadFile(
    url: string,
    convertTo: 'any' | 'Uint8Array' | 'Blob' = 'Blob',
    isS3Call: boolean = false
  ): Observable<{ blob: Blob, name: string }> {

    let httpClient = this.httpClient;

    if (isS3Call) {
      httpClient = httpClient.disableApiPrefix();
    }

    if (isS3Call) {
      httpClient = httpClient.disableAccessToken();
    }

    return httpClient
      .get<Blob>(
        `${url}`,
        {
          observe: 'response', responseType: 'blob' as 'json',
          headers: {
            'Access-Control-Expose-Headers': 'x-amz-meta-title'
          }
        }
      )
      .pipe(
        map((finalResponse: HttpResponse<Blob>) => {
          switch (convertTo) {
            case 'Blob':
              return { blob: new Blob([finalResponse.body]), name: this.getFileName(finalResponse) };
            default:
              return null;
          }
        })
      )
  }

  createReport<T>(
    url: string,
    data: T,
    convertTo: 'any' | 'Uint8Array' | 'Blob' = 'Blob',
    isS3Call: boolean = false
  ): Observable<{ blob: Blob, name: string }> {

    let httpClient = this.httpClient;

    if (isS3Call) {
      httpClient = httpClient.disableApiPrefix();
    }

    if (isS3Call) {
      httpClient = httpClient.disableAccessToken();
    }

    return httpClient
      .post<Blob>(
        `${url}`,
        data,
        {
          observe: 'response', responseType: 'blob' as 'json',
          headers: {
            'Access-Control-Expose-Headers': 'x-amz-meta-title'
          }
        }
      )
      .pipe(
        map((finalResponse: HttpResponse<Blob>) => {
          switch (convertTo) {
            case 'Blob':
              return { blob: new Blob([finalResponse.body]), name: this.getFileName(finalResponse) };
            default:
              return null;
          }
        })
      )
  }

  downloadFileByIds(ids: string[], mode: 'combine'): Observable<{ blob: Blob, name: string }> {
    const idsParameter = ids.map(q => `ids=${q}`).join('&');

    return this.httpClient
      .get<Blob>(
        `files?mode=${mode}&${idsParameter}`,
        {
          observe: 'response', responseType: 'blob' as 'json',
        }
      )
      .pipe(
        map((finalResponse: HttpResponse<Blob>) => {
          return { blob: new Blob([finalResponse.body]), name: this.getFileName(finalResponse) };
        })
      );
  }

  // tslint:disable-next-line:max-line-length
  uploadFiles(
    fileContainers: FileContainerBase[],
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ): Observable<DatabaseFile> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (fileContainers.length === 0) {
        subscriber.complete();
        return;
      }
      this._uploadFiles(
        subscriber,
        fileContainers.filter(q => q.state === FileState.New),
        0,
        resource,
        entityId,
        resourceAndQueryParametersSelector
      );
    });
  }

  private getFileName(response: HttpResponse<Blob>) {
    let filename: string;

    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      filename = contentDisposition.substring(
        contentDisposition.lastIndexOf('filename=') + 9,
        contentDisposition.lastIndexOf(';')
      );
      filename = filename.replace('"', '');
      filename = filename.replace('"', '');
    }
    catch (e) {
      try {
        filename = response.headers.get('x-amz-meta-title');
      }
      catch (e) {
        filename = 'myfile.pdf';
      }
    }

    return filename;
  }

  // tslint:disable-next-line:max-line-length
  private _uploadFiles(
    subscriber: Subscriber<any>,
    fileContainers: FileContainerBase[],
    fileContainerIndex: number,
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ) {
    super.addSubscription(
      this.uploadFile(
        fileContainers[fileContainerIndex],
        resource,
        entityId,
        resourceAndQueryParametersSelector
      ).subscribe({
        next: (file: DatabaseFile) => {
          subscriber.next(file);
          if (__.IsNullOrUndefined(fileContainers[fileContainerIndex + 1])) {
            subscriber.complete();
          } else {
            this._uploadFiles(
              subscriber,
              fileContainers,
              fileContainerIndex + 1,
              resource,
              entityId,
              resourceAndQueryParametersSelector
            );
          }
        }
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  private uploadFile(
    fileContainer: FileContainerBase,
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ): Observable<DatabaseFile> {
    const formData = new FormData();

    formData.append('file', (fileContainer.original as any).file);

    return this.httpClient
      .post<Response<DatabaseFile>>(
        `${resource}/${entityId}/${resourceAndQueryParametersSelector(fileContainer)}`,
        formData
      )
      .pipe(
        map((result: Response<DatabaseFile>) => {
          return result.data;
        })
      );
  }
}
