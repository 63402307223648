import { OrderLineItem } from '@app/orders/form/orders-form.component';

import { Address } from '../Address';
import { ApplicationUser } from '../ApplicationUser';
import { BaseModel } from '../BaseModel';
import { DeliveryMethod } from '../delivery-methods/DeliveryMethod';
import { DatabaseFile } from '../File';
import { OrderStatus } from './OrderStatus';

export class Order extends BaseModel {
    number: string;

    /**
     * The original order ID in the external 3rd party system
     */
    externalId: string;

    slotId: string;
    fulfillmentQuoteId: string;

    /**
     * The carrier tracking number of a shipment with which the package can be tracked
     */
    trackingNumber: string;

    /**
     * The carrier tracking number of a shipment with which the package can be tracked
     */
    trackingLink: string;

    /**
     * The carrier tracking number of a shipment retoure with which the package can be tracked
     */
    trackingNumberReturn: string;

    /**
     * Additional order description e.g. special information about the delivery place
     */
    comment: string;
    recipient: ApplicationUser;
    recipientId: string;
    modifiedAt: string;
    files: DatabaseFile[] = [];
    createdAt: string;
    status: OrderStatus;
    deliveryMethod: DeliveryMethod;
    deliveryMethodId: string;
    deliveryAddress: Address;
    addressId: string;
    statusHistories: StatusHistory[] = [];
    lineItems: OrderLineItem[] = [];
    option: FulfillmentQuoteDetailOption;
    optionId: string;
    slot: FulfillmentQuoteDetailOptionSlot;
}

export class StatusHistory {
    id: string;
    status: OrderStatus;
    createdAt: string;
    providerName: string;
}

export class FulfillmentQuoteDetailOption {
    id: string;
    title: string;
    deliveryMethodType: string;
    deliveryMethodId: string;
    type: string;
    subTitle: string;
    minimumRate: any;
}

export class FulfillmentQuoteDetailOptionSlot {
    id: string;
    utcStartAt: string;
    utcEndAt: string;
}

export class OrderUpdateStatusOptions {
    orderIds: string[];
    type: string;
}
