import { NgModule } from '@angular/core';

import { EntityListContentContainerComponent } from './entity-list-content-container.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [EntityListContentContainerComponent],
  exports: [EntityListContentContainerComponent]
})
export class EntityListContentContainerModule { }
