import { KeyValue } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Directive, Injector, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfigurableTableDialogComponent
} from '@app/shared/components/configurable-table-dialog/configurable-table-dialog.component';
import { __ } from '@app/shared/functions/object.functions';
import { BaseModel } from '@app/shared/models/classes/BaseModel';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import {
  ConfigurableTableColumn,
  ConfigurableTableDialogParameters,
  ConfigurableTableSettings
} from '../models/configurable-table-settings';
import { BaseFilterableComponent } from './base-filterable.component';


@Directive()
export abstract class BaseFilterableTableComponent<T extends BaseModel> extends BaseFilterableComponent<T> {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  createdDateText: string;

  createdStartText: string;

  createdEndText: string;

  modifiedDateText: string;

  modifiedStartText: string;

  modifiedEndText: string;

  minStartDate = new Date(2018, 1, 1);

  maxStartDate = new Date();

  isMobile: boolean = false;

  minEndDate = new Date(2018, 1, 1);

  maxEndDate = new Date();

  isLoadingFilters = true;

  isLoadingPdfExport: boolean = false;

  displayedColumns: string[] = [];

  rowCount: number = 0;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() showTitle: boolean = true;

  @Input() showHeader: boolean = true;

  @Input() withSelector: boolean = false;

  @Input() showActions: boolean = true;

  @Input() reflectFiltersInUrl: boolean = true;

  
  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  protected toastr: ToastrService;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _selected: T[] = [];
  @Input()
  get selected(): T[] {
    return this._selected;
  }
  set selected(values: T[]) {
    if (!__.IsNullOrUndefined(values)) {

      const difference = values.filter(q => !__.IsNullOrUndefined(q))
        .filter(x => !this.selection.selected.includes(x))
        .concat(this.selection.selected.filter(x => !values.includes(x)));

      if (difference.length > 0) {
        this.selection.clear();
        for (const value of values) {
          this.selection.select(value);
        }
      }
    }
    this._selected = values;
  }

  private _defaultColumns: KeyValue<string, string>[] = [];
  @Input()
  get defaultColumns(): KeyValue<string, string>[] {
    return this._defaultColumns;
  }
  set defaultColumns(value: KeyValue<string, string>[]) {
    this._defaultColumns = value;
    this.applyConfigurableTableSettings();
  }

  private _localStorageKey: string;
  @Input()
  get localStorageKey(): string {
    return this._localStorageKey;
  }
  set localStorageKey(value: string) {
    this._localStorageKey = value;
    this.applyConfigurableTableSettings();
  }

  private _showSelectColumn: boolean = false;
  @Input()
  get showSelectColumn(): boolean {
    return this._showSelectColumn;
  }
  set showSelectColumn(value: boolean) {
    this._showSelectColumn = value;
    this.applyConfigurableTableSettings();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private httpClient: HttpClient;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(protected injector: Injector, protected _configurableTableSettings: ConfigurableTableSettings) {
    super(injector);

    this._defaultColumns = _configurableTableSettings.defaultColumns;
    this._localStorageKey = _configurableTableSettings.localStorageKey;
    this._showSelectColumn = _configurableTableSettings.showSelectColumn;

    this.httpClient = this.injector.get(HttpClient);
    this.toastr = this.injector.get(ToastrService);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setShouldNavigate($event: boolean): void {
    this.shouldNavigate = false;
    setTimeout(() => {
      this.shouldNavigate = true;
    }, 10);
  }

  trackRowCount(): void {
    super.addSubscription(
      this.dataSource.database.dataChanged$.subscribe(() => {
        this.rowCount = this.dataSource.database.paginator?.length;
      })
    );
  }

  showTableSettingsDialog() {
    const data = Object.assign(new ConfigurableTableDialogParameters(), {
      defaultSettings: this._defaultColumns.map((keyValuePair: KeyValue<string, string>) => {
        return Object.assign(new ConfigurableTableColumn(), {
          key: keyValuePair.key,
          value: keyValuePair.value,
          visibility: true
        });
      }),
      localStorageKey: this._localStorageKey
    });

    super.addSubscription(
      this.injector
        .get(MatDialog)
        .open(ConfigurableTableDialogComponent, { data })
        .afterClosed()
        .subscribe((columns: ConfigurableTableColumn[]) => {
          if (!__.IsNullOrUndefined(columns)) {
            localStorage.setItem(this._localStorageKey, JSON.stringify(columns));
            this.applyConfigurableTableSettings(columns);
          }
        })
    );
  }

  exportAsPdf(): void {
    this.isLoadingPdfExport = true;

    let idsQueryParameter = '';

    if (this.selection.hasValue()) {
      for (const selected of this.selection.selected) {
        idsQueryParameter = `${idsQueryParameter}ids=${selected.id}&`
      }
    }

    super.addSubscription(
      this.httpClient
        .post<Blob>(
          `${this.dataSource.database.configuration.endpoint}/pdf?${idsQueryParameter}`,
          null,
          { observe: 'response', responseType: 'blob' as 'json' }
        )
        .pipe(
          finalize(() => {
            this.isLoadingPdfExport = false;
          })
        )
        .subscribe({
          next: (response: HttpResponse<Blob>) => {
            const blob = new Blob([response.body], { type: 'application/pdf' });
            const fileName = this.getFileName(response)
            saveAs(blob, fileName);
            this.toastr.success('The pdf has been downloaded');
          },
          error: (error: any) => {
            this.toastr.error('The pdf could not be exported');
          }
        })
    );
  }

  /**
   * Resets the date range filter.
   * @param dateFieldStart Name of the first date field.
   * @param dateFieldEnd Name of the second date field.
   */
  resetDateRanges(dateFieldStart: string | AbstractControl, dateFieldEnd: string | AbstractControl) {
    super.resetDateRanges(dateFieldStart, dateFieldEnd);
    this.setBoundariesToDefault();

    this.createdDateText = '';
    this.modifiedDateText = '';
    this.createdStartText = '';
    this.createdEndText = '';
    this.modifiedStartText = '';
    this.modifiedEndText = '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * The boundaries for the date range controls need to be set so that validation isn't needed
   * because the user isn't able to set wrong dates in the first place.
   * If the start date range field is set the end date range can only be set as date that lies in the future
   * (default is one month after the start range).
   * The same applies for the end date range:
   * If it is set the start date range that is selectable should atleast be one month before the end date.
   * @param formControl The name of the form control which was changed by the user.
   */
  protected setDateRangeBoundaries(formControl: string) {
    // minEndDate
    if (formControl.includes('Start')) {
      this.minEndDate = this.setDate(formControl, 1);
    }

    // maxStartDate
    if (formControl.includes('End')) {
      this.maxStartDate = this.setDate(formControl, -1);
    }
  }

  protected setBoundariesToDefault() {
    this.minEndDate = this.minStartDate;
    this.maxStartDate = this.maxEndDate;
  }

  /**
   * If a date is bigger than a certain day of month return a new Date with an offset of months
   * @param date Date which is the default date.
   * @param offsetMonths Offset of months which can be positive or negative.
   * @param dayOfMonth Day of month which is the border. Everythin bigger than this border will trigger the calculation of the offset date.
   */
  protected getDateWithOffset(date: Date, offsetMonths: number, dayOfMonth: number) {
    if (date.getDate() > dayOfMonth) {
      if (!__.IsNullOrUndefined(date)) {
        return new Date(date.getFullYear(), date.getMonth() + offsetMonths, date.getDate());
      }
    }
    return date;
  }

  protected applyConfigurableTableSettings(columns?: ConfigurableTableColumn[]): void {
    if (!__.IsNullOrUndefined(columns)) {
      // Set new columns
      const displayedColumns = columns;

      // If the select column should be displayed and it does not exist in the displayed columns
      if (this._showSelectColumn === true && displayedColumns.findIndex(q => q.key === 'select') === -1) {
        displayedColumns.unshift({ key: 'select', value: 'select', visibility: true });
      }

      // Set the items to the local storage
      localStorage.setItem(this._localStorageKey, JSON.stringify(displayedColumns));

      // Set the GUI
      this.displayedColumns = displayedColumns.filter(q => q.visibility).map(q => q.key);

    } else {
      // Load existing columns

      const localStorageSettings = JSON.parse(localStorage.getItem(this._localStorageKey)) as ConfigurableTableColumn[];

      if (__.IsNullOrUndefined(localStorageSettings) || localStorageSettings.length === 0) {
        // There are no existing settings
        const displayedExistingColumns = this._defaultColumns.map((q: any) => {
          q.visibility = true;
          return q;
        });

        // If the select column should be displayed and it does not exist in the displayed columns
        if (this._showSelectColumn === true && displayedExistingColumns.findIndex(q => q.key === 'select') === -1) {
          displayedExistingColumns.unshift({ key: 'select', value: 'select', visibility: true });
        }

        // Set the items to the local storage
        localStorage.setItem(this._localStorageKey, JSON.stringify(displayedExistingColumns));

        // Set the GUI
        this.displayedColumns = displayedExistingColumns.filter(q => q.visibility).map(q => q.key);

        return;
      }

      // There are existing settings, so merge them with default settings
      const newColumns = this._defaultColumns.filter(q => !localStorageSettings.some(a => a.key === q.key));
      const removedColumns = localStorageSettings.filter(q => !this._defaultColumns.some(a => a.key === q.key));
      const existingColumns = localStorageSettings.filter(q => this._defaultColumns.some(a => a.key === q.key));

      const displayedColumns = [...existingColumns, ...newColumns.map((q: any) => {
        q.visibility = true;
        return q;
      })];

      // If the select column should be displayed and it does not exist in the displayed columns
      if (this._showSelectColumn === true && displayedColumns.findIndex(q => q.key === 'select') === -1) {
        displayedColumns.unshift({ key: 'select', value: 'select', visibility: true });
      }

      // Set the GUI
      this.displayedColumns = displayedColumns.filter(q => q.visibility).map(q => q.key);
    }
  }

  protected updateText(formControl: string) {
    if (this.IsNullOrUndefined(this.filtersForm.get(formControl).value)) {
      // *NOTE: if the user has removed the date value per hand and not via "Reset filter"-button
      // *NOTE: a workaround would be making the dateinput field only selectable via datepicker and the field readonly
      this.createdEndText = '';
      this.modifiedEndText = '';
    } else {
      switch (formControl) {
        case 'createdStart':
          this.createdStartText = this.setFormatForDateText(formControl);
          this.createdDateText = this.createdStartText;
          break;
        case 'createdEnd':
          this.createdEndText = this.setFormatForDateText(formControl);
          this.createdDateText = this.createdEndText;
          break;
        case 'modifiedStart':
          this.modifiedStartText = this.setFormatForDateText(formControl);
          this.modifiedDateText = this.modifiedStartText;
          break;
        case 'modifiedEnd':
          this.modifiedEndText = this.setFormatForDateText(formControl);
          this.modifiedDateText = this.modifiedEndText;
          break;
        default:
          break;
      }

      this.createdDateText = super.setDateRangeText(this.createdStartText, this.createdEndText);
      this.modifiedDateText = super.setDateRangeText(this.modifiedStartText, this.modifiedEndText);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      filename = contentDisposition.substring(
        contentDisposition.lastIndexOf('filename=') + 9,
        contentDisposition.lastIndexOf(';')
      );
    }
    catch (e) {
      try {
        filename = response.headers.get('x-amz-meta-title');
      }
      catch (e) {
        filename = 'myfile.pdf';
      }
    }

    return filename;
  }
}