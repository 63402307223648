import { NgModule } from '@angular/core';

import { EntityFilterModule } from '../entity-filter/entity-filter.module';
import { EntityFilterCheckboxComponent } from './entity-filter-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatCheckboxModule,
    EntityFilterModule
  ],
  declarations: [EntityFilterCheckboxComponent],
  exports: [EntityFilterCheckboxComponent]
})
export class EntityFilterCheckboxModule { }
