import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import saveAs from 'file-saver';
import { AuthenticationService } from 'projects/partner/src/app/core/authentication/authentication.service';
import { SharedModule } from 'projects/partner/src/app/shared';
import { ButtonsCancelModule } from 'projects/partner/src/app/shared/components/buttons/cancel/buttons-cancel.module';
import { ButtonsDefaultModule } from 'projects/partner/src/app/shared/components/buttons/default/buttons-default.module';
import { ButtonsSubmitModule } from 'projects/partner/src/app/shared/components/buttons/submit/buttons-submit.module';
import { DialogModule } from 'projects/partner/src/app/shared/components/dialog/dialog.module';
import { BaseDialogComponent } from 'projects/partner/src/app/shared/components/dialog/models/base/base-dialog.component';
import { FileType } from 'projects/partner/src/app/shared/components/files/models/FileType.enum';
import { GenericMenuItem } from 'projects/partner/src/app/shared/components/generic-menu/generic-menu-item.model';
import {
    MessagesSuccessModule,
} from 'projects/partner/src/app/shared/components/messages/messages-success/messages-success.module';
import {
    MessagesWarningModule,
} from 'projects/partner/src/app/shared/components/messages/messages-warning/messages-warning.module';
import { __ } from 'projects/partner/src/app/shared/functions/object.functions';
import { DatabaseFile } from 'projects/partner/src/app/shared/models/classes/File';
import { Order } from 'projects/partner/src/app/shared/models/classes/orders/Order';
import { OrderStatus } from 'projects/partner/src/app/shared/models/classes/orders/OrderStatus';
import { FilesService } from 'projects/partner/src/app/shared/services/files.service';
import { OrdersService } from 'projects/partner/src/app/shared/services/orders.service';
import { catchError, concatMap, finalize, map, Observable, of, Subject, switchMap } from 'rxjs';

export class OrdersListDownloadLabelsDialogComponentData {
    orders: Order[] = [];
}

@Component({
    standalone: true,
    selector: 'sendable-orders-list-download-labels-dialog',
    imports: [
        SharedModule,

        DialogModule,
        ButtonsSubmitModule,
        ButtonsCancelModule,
        ButtonsDefaultModule,

        MessagesSuccessModule,
        MessagesWarningModule,

        MatProgressSpinnerModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule
    ],
    providers: [],
    templateUrl: './orders-list-download-labels-dialog.component.html',
    styleUrls: ['./orders-list-download-labels-dialog.component.scss']
})
export class OrdersListDownloadLabelsDialogComponent extends BaseDialogComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    menuItems: GenericMenuItem[] = [];

    isLoadingDownload: boolean = false;

    hasGenerated: boolean = false;

    labelsGeneratedAmount: number = 0;

    labelsGeneratedErrorAmount: number = 0;

    downloadActions: { displayName: string, key: string, isDisabled: () => boolean }[] = [];

    FileType = FileType;

    selectedAction: string;

    isLoadingAction: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private messageQueue = new Subject<Order>();

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OrdersListDownloadLabelsDialogComponentData,
        public dialogRef: MatDialogRef<OrdersListDownloadLabelsDialogComponent>,
        public dialog: MatDialog,
        public authenticationService: AuthenticationService,
        private filesService: FilesService,
        private ordersService: OrdersService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    downloadLabels(): void {
        this.hasGenerated = false;
        this.labelsGeneratedErrorAmount = 0;
        this.labelsGeneratedAmount = 0;

        this.initializeQueue();

        for (const order of this.data.orders) {
            order['dialogIsLabelGenerationError'] = false;
            order['dialogIsLabelGenerationSuccess'] = false;

            this.messageQueue.next(
                order
            )
        }

        this.messageQueue.complete();
    }

    downloadLabel(fileId: string, row?: Order): void {
        if (!__.IsNullOrUndefined(row)) {
            row['dialogIsLoadingLabelDownload'] = true;
        }

        super.addSubscription(
            this.downloadFile(fileId)
                .pipe(
                    finalize(() => {
                        if (!__.IsNullOrUndefined(row)) {
                            row['dialogIsLoadingLabelDownload'] = false;
                        }
                    })
                )
                .subscribe()
        );
    }

    labelCanBeCreated(item: Order): boolean {
        return ((item.deliveryMethod as any) !== 'Selbstabholung' && item.status !== OrderStatus.Canceled) || this.authenticationService.isSuperAdmin();
    }

    generateLabel(row: Order): void {
        row['dialogIsLoadingLabelGeneration'] = true;
        row['dialogIsLabelGenerationError'] = false;
        row['dialogIsLabelGenerationSuccess'] = false;

        super.addSubscription(
            this.ordersService
                .createDeliveryLabel(row.id)
                .pipe(
                    switchMap((file: DatabaseFile) => {
                        row.files.push(file);
                        return this.downloadFile(file.id)
                    }),
                    finalize(() => {
                        row['dialogIsLoadingLabelGeneration'] = false;
                    }),
                )
                .subscribe({
                    next: () => {
                        row['dialogIsLabelGenerationSuccess'] = true;
                    },
                    error: (error: HttpErrorResponse) => {
                        row['dialogIsLabelGenerationError'] = true;
                        row['dialogLabelGenerationError'] = error?.error?.message;
                    }
                })
        )
    }

    initializeQueue(): void {
        this.isLoadingAction = true;

        const applicableFileIds: string[] = [];

        this.messageQueue.pipe(
            concatMap((order: Order) => {
                order['dialogIsLoadingLabelGeneration'] = true;

                return this.ordersService
                    .createDeliveryLabel(order.id)
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            order['dialogIsLabelGenerationError'] = true;
                            order['dialogLabelGenerationError'] = error?.error?.message;
                            this.labelsGeneratedErrorAmount = this.labelsGeneratedErrorAmount + 1;

                            return of(null);
                        }),
                        map((file: DatabaseFile) => {
                            order['dialogIsLoadingLabelGeneration'] = false;

                            if (!__.IsNullOrUndefined(file)) {
                                if (__.IsNullOrUndefined(order.files)) {
                                    order.files = [];
                                }

                                if (order.files.length === 0) {
                                    order.files.push(file);
                                }
                                order['dialogIsLabelGenerationSuccess'] = true;
                                applicableFileIds.push(file.id);
                            }
                        })
                    )
            })
        ).subscribe({
            complete: () => {
                this.labelsGeneratedAmount = applicableFileIds.length;

                if (applicableFileIds.length === 1) {
                    this.hasGenerated = true;
                    this.downloadLabel(applicableFileIds[0]);
                    this.isLoadingAction = false;
                } else if (applicableFileIds.length > 1) {
                    super.addSubscription(
                        this.filesService
                            .downloadFileByIds(applicableFileIds, 'combine')
                            .pipe(
                                finalize(() => {
                                    this.hasGenerated = true;
                                    this.isLoadingAction = false;
                                })
                            )
                            .subscribe({
                                next: (data: { blob: Blob, name: string }) => {
                                    saveAs(data.blob, data.name);
                                }
                            })
                    );
                } else {
                    this.isLoadingAction = false;
                }

            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PROTECTED METHODS
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    private downloadFile(itemId: string): Observable<any> {
        return this.filesService.downloadFile(
            `files/${itemId}`
        ).pipe(
            map((data: { blob: Blob, name: string }) => {
                saveAs(data.blob, data.name);

                return data;
            })
        );
    }
}
