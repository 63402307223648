import { KeyValue } from '@angular/common';

export class ConfigurableTableSettings {
    localStorageKey: string;
    defaultColumns: KeyValue<string, string>[] = [];
    showSelectColumn: boolean = false;
}

export class ConfigurableTableColumn {
    visibility: boolean;
    key: string;
    value: string;
}

export class ConfigurableTableDialogParameters {
    defaultSettings: ConfigurableTableColumn[];
    localStorageKey: string;
    showSelectColumn: boolean = false;
}
