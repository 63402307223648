<div class="kb-entity-list__content-container"  >

  <div  class="kb-entity-list__filters-container filters" *ngIf="showFilters === true">
    <ng-content select="kb-entity-list-filters-container"></ng-content>
  </div> <!-- /kb-entity-list__filters-container -->

  <div  class="kb-entity-list__table">
    <ng-content select="kb-entity-list-table"></ng-content>
  </div> <!-- /kb-entity-list__table scrollable-table -->

</div> <!-- /kb-entity-list__content-container -->