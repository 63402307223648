import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListHeaderCountComponent } from './entity-list-header-count.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityListHeaderCountComponent],
  exports: [EntityListHeaderCountComponent]
})
export class EntityListHeaderCountModule { }
