<!-- HEADER -->
<div class="kb-entity-list__header-container flex-initial" [class.show-filters]="showFilters === true">
  <div class="kb-entity-list__header">
    <ng-content select="kb-entity-list-header"></ng-content>
  </div> <!-- /kb-entity__list-header -->
</div> <!-- kb-entity__list-header-container -->

<!-- CONTENT -->
<div class="kb-entity__list-content-container flex-auto bg-white">
  <ng-content select="kb-entity-list-content-container"></ng-content>
</div>

<!-- FOOTER -->
<div class="kb-entity__list-footer flex-auto">
  <ng-content select="kb-entity-list-footer"></ng-content>
</div>