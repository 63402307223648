import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-entity-filter-search',
  templateUrl: './entity-filter-search.component.html',
  styleUrls: ['./entity-filter-search.component.scss']
})
export class EntityFilterSearchComponent extends BaseComponent implements OnInit {

  @Input() title: string = 'Suchen';

  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() control: FormControl;

  ngOnInit() {
  }

}
