import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListComponent } from './entity-list.component';
import { EntityListHeaderModule } from './header/entity-list-header.module';
import { EntityListContentContainerModule } from './content-container/entity-list-content-container.module';
import { EntityListFiltersContainerModule } from './filters-container/entity-list-filters-container.module';
import { EntityListTableModule } from './table/entity-list-table.module';
import { EntityFiltersModule } from '../entity-filters/entity-filters.module';
import { EntityListFooterModule } from './footer/entity-list-footer.module';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityListComponent],
  exports: [
    EntityListComponent,

    EntityListHeaderModule,
    EntityListContentContainerModule,
    EntityListFiltersContainerModule,
    EntityListTableModule,
    EntityListFooterModule,

    EntityFiltersModule
  ]
})
export class EntityListModule { }
