import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListHeaderActionsComponent } from './entity-list-header-actions.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityListHeaderActionsComponent],
  exports: [EntityListHeaderActionsComponent]
})
export class EntityListHeaderActionsModule { }
