import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityListHeaderSettingsComponent } from './entity-list-header-settings.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityListHeaderSettingsComponent],
  exports: [EntityListHeaderSettingsComponent]
})
export class EntityListHeaderSettingsModule { }
