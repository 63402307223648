import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EntityListHeaderActionsModule } from './actions/entity-list-header-actions.module';
import { EntityListHeaderCountModule } from './count/entity-list-header-count.module';
import { EntityListHeaderComponent } from './entity-list-header.component';
import { EntityListHeaderSettingsModule } from './settings/entity-list-header-settings.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingButtonModule } from '../../loading-button/loading-button.module';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    LoadingButtonModule
  ],
  declarations: [EntityListHeaderComponent],
  exports: [
    EntityListHeaderComponent,

    EntityListHeaderActionsModule,
    EntityListHeaderCountModule,
    EntityListHeaderSettingsModule
  ]
})
export class EntityListHeaderModule { }
