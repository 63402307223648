import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EntityListFooterComponent } from './entity-list-footer.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityListFooterComponent],
  exports: [EntityListFooterComponent]
})
export class EntityListFooterModule { }
