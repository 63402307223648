<div class="kb-entity-list__header__content flex flex-row flex-wrap md:flex-nowrap">

  <!-- COUNT -->
  <div class="kb-entity-list__header__count-container flex-auto">
    <ng-content select="kb-entity-list-header-count"></ng-content>
  </div> <!-- /kb-entity-list__header__count-container -->

  <!-- ACTIONS -->
  <div class="kb-entity-list__header__actions-container flex-initial mt-4 md:mt-0">
    <ng-content select="kb-entity-list-header-actions"></ng-content>
  </div> <!-- /kb-entity-list__header__actions-container -->

  <!-- SETTINGS -->
  <div class="kb-entity-list__header__settings-container mt-4 md:mt-0 flex-initial">
    <div  >
      <div >
        <!-- <button [matTooltip]="tableSettingsTooltip" mat-icon-button (click)="tableSettingsClicked.emit(true)">
          <mat-icon style="line-height: 22px;">settings</mat-icon>
        </button> -->
      </div>
      <div  class="paginator">
        <ng-content select="kb-entity-list-header-settings"></ng-content>
      </div>
    </div>
  </div> <!-- /kb-entity-list__header__settings-container -->

</div> <!-- /kb-entity-list__header__content -->