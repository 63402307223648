export enum OrderStatus {
    // A label has been deleted
    LabelDeleted = 'LabelDeleted',

    // A label has been created
    LabelCreated = 'LabelCreated',

    // Shipment awaits fulfillment
    AwaitsFulfillment = 'AwaitsFulfillment',

    // Shipment is being fulfilled
    Fulfillment = 'Fulfillment',

    // Shipment was picked up by carrier
    PickedUp = 'PickedUp',

    // Shipment is in transit
    Transit = 'Transit',

    // Out for delivery
    OutForDelivery = 'OutForDelivery',

    // Delivered
    Delivered = 'Delivered',

    // Awaiting pickup by the receiver
    AwaitsPickupByReceiver = 'AwaitsPickupByReceiver',

    // The label has been deleted
    Canceled = 'Canceled',

    // Delivery will be delayed
    Delayed = 'Delayed',

    // There's a problem with the shipment
    Exception = 'Exception',

    // Not delivered
    NotDelivered = 'NotDelivered',

    // Destroyed by customs or other higher authorities.
    Destroyed = 'Destroyed',

    // Carrier internal notification. Tracking events within the shipment will carry more elaborate information.
    Notification = 'Notification',

    // Status unknown
    Unknown = 'Unknown',

    // Status the carrier has picked up the package from the customer
    ReturnTransit = 'ReturnTransit',

    // TODO : Add returning status
}
