import { Guid } from '@app/shared/functions/guid';

export class GenericMenuItem {
  actionType?: 'Create' | 'Edit' | 'Delete' | 'View' | string = '';
  context?: any;
  disabled?: any = false;
  isLoading?: any = false; // boolean | (() => boolean) = false;
  isLoadingButton?: boolean = false;
  displayName?: string = '';
  icon?: string;
  customClass?: any;
  execute?: (item: any) => any;
  routerLink?: string;
  visible?: any = true;
  color?: string = '';
  identifier?: string = Guid.NewGuid();
}
