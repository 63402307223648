import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kb-entity-list-header-actions',
  templateUrl: './entity-list-header-actions.component.html',
  styleUrls: ['./entity-list-header-actions.component.scss']
})
export class EntityListHeaderActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
