import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { BaseModel } from '@app/shared/models/classes/BaseModel';

@Component({
  selector: 'kb-entity-list-header',
  templateUrl: './entity-list-header.component.html'
})
export class EntityListHeaderComponent<T extends BaseModel> extends BaseComponent implements OnInit {

  @Input() showTableSettings: boolean = true;

  @Input() tableSettingsTooltip: string = 'Change table settings';

  @Input() selection: SelectionModel<T>;

  @Output() tableSettingsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
