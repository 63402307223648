<button style="height: 34px;" (click)="isDisabled || isLoading ? null : onClick.emit()" matRipple [matRippleDisabled]="isDisabled || isLoading" [matTooltipDisabled]="tooltipDisabled"
[ngClass]="{ 'disabled': isDisabled === true || isLoading === true }"
[matTooltip]="tooltipText" [style]="buttonStyle" [disabled]="isDisabled || isLoading" [routerLink]="routerLink ?? null"
  class="hover:opacity-80 h-10 leading-normal hover:text-white group flex items-center rounded-md bg-primary-blue text-white text-sm font-medium px-4 py-2">
  <div class="flex items-center">
    <svg class="group-hover:text-light-blue-600 text-light-blue-500 mr-2" width="12" height="20" fill="currentColor">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6 5a1 1 0 011 1v3h3a1 1 0 110 2H7v3a1 1 0 11-2 0v-3H2a1 1 0 110-2h3V6a1 1 0 011-1z" />
    </svg>
    <ng-content></ng-content>
  </div>
  <!-- LOADER -->
  <ng-container *ngIf="isLoading && order !== 'icon-last'">
    <mat-spinner class="ml-4" [diameter]="20"></mat-spinner>
</ng-container>
</button>