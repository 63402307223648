import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EntityFilterCheckboxModule } from './checkbox/entity-filter-checkbox.module';
import { EntityFilterDateRangeModule } from './date-range/entity-filter-date-range.module';
import { EntityFiltersComponent } from './entity-filters.component';
import { EntityFilterRangeModule } from './range/entity-filter-range.module';
import { EntityFilterSearchModule } from './search/entity-filter-search.module';
import { EntityFilterModule } from './entity-filter/entity-filter.module';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [EntityFiltersComponent],
  exports: [
    EntityFiltersComponent,
  
    EntityFilterSearchModule,
    EntityFilterCheckboxModule,
    EntityFilterDateRangeModule,
    EntityFilterRangeModule,
    EntityFilterModule
  ]
})
export class EntityFiltersModule { }
