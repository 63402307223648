import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
  selector: 'kb-form-deactivateable-dialog',
  templateUrl: './form-deactivateable-dialog.component.html',
  styleUrls: ['./form-deactivateable-dialog.component.scss']
})
export class FormDeactivateableDialogComponent extends BaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormDeactivateableDialogComponent>,
  ) {
    super();
  }
}
