import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../../core/authentication/authentication.service';
import { AppRoute } from '../../shared/app.route.enum';
import { BaseDatabase } from '../../shared/base/components/base-database.component';
import { BaseDataSource } from '../../shared/base/components/base-datasource.component';
import { BaseFilterableTableComponent } from '../../shared/base/components/base-filterable-table.component';
import { ConfigurableTableSettings } from '../../shared/base/models/configurable-table-settings';
import { DatabaseConfiguration } from '../../shared/base/models/database-configuration';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { FileType } from '../../shared/components/files/models/FileType.enum';
import { GenericMenuItem } from '../../shared/components/generic-menu/generic-menu-item.model';
import { __ } from '../../shared/functions/object.functions';
import { DatabaseFile } from '../../shared/models/classes/File';
import { Order as TEntity } from '../../shared/models/classes/orders/Order';
import { OrderStatus } from '../../shared/models/classes/orders/OrderStatus';
import { FilesService } from '../../shared/services/files.service';
import { OrdersService, OrderUpdateStatusOptions } from '../../shared/services/orders.service';
import { OrderRoute } from '../shared/order.route.enum';
import {
  OrdersListDownloadLabelsDialogComponent,
} from './shared/download-labels-dialog/orders-list-download-labels-dialog.component';

@Component({
  selector: 'kb-orders-list',
  templateUrl: './orders-list.component.html'
})
export class OrdersListComponent extends BaseFilterableTableComponent<TEntity> implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  menuItems: GenericMenuItem[] = [];

  isLoadingDownload: boolean = false;

  downloadActions: { displayName: string, key: string, isDisabled: () => boolean }[] = [];

  FileType = FileType;

  selectedAction: string;

  isLoadingAction: boolean = false;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() pageSize: number = 20;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected injector: Injector,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translateService: TranslateService,
    public authenticationService: AuthenticationService,
    private filesService: FilesService,
    private ordersService: OrdersService
  ) {
    super(
      injector,
      Object.assign(new ConfigurableTableSettings(), {
        localStorageKey: 'orders-table-v4',
        defaultColumns: [
          { key: 'select', value: 'Orders.List.Columns.Selection' },
          { key: 'number', value: 'Orders.List.Columns.Order number' },
          { key: 'recipient', value: 'Orders.List.Columns.Recipient' },
          { key: 'status', value: 'Orders.List.Columns.Status' },
          { key: 'created', value: 'Orders.List.Columns.Received at' },
          { key: 'deliveryPeriod', value: 'Orders.List.Columns.Delivery period' },
          { key: 'deliveryMethod', value: 'Orders.List.Columns.Delivery method' },
          { key: 'labels', value: 'Orders.List.Columns.Labels' },
          { key: 'actions', value: 'Orders.List.Columns.Actions' },
        ]
      })
    );

    this.setFiltersForm();
    this.setDownloadActions();
    super.initAndMergeObservables();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.setDataSource();

    super.trackRowCount();
    super.trackLoading();
    super.applyConfigurableTableSettings();
    super.seedInitialLoadingData(TEntity, 5);
    super.listenForEmptySearchFilter();

    this.setMenuItems();

    super.addSubscription(
      this.selection.changed.subscribe({
        next: () => { this.selectedAction = null; }
      })
    )
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  navigateToNewEntity(): void {
    this.router.navigate([`${AppRoute.Orders}/${OrderRoute.Create}`]);
  }

  navigateToEntity($event: TEntity): void {
    if (this.shouldNavigate === true) {
      this.router.navigate([`${AppRoute.Orders}/${$event.id}/${OrderRoute.Edit}`]);
    }
  }

  navigateToEntityEdit($event: TEntity): void {
    if (this.shouldNavigate === true) {
      this.router.navigate([`${AppRoute.Orders}/${$event.id}/${OrderRoute.Edit}`]);
    }
  }

  generateLabel(row: TEntity): void {
    this.setShouldNavigate(false);
    row['isLoadingLabelGeneration'] = true;

    super.addSubscription(
      this.ordersService
        .createDeliveryLabel(row.id)
        .pipe(
          switchMap((file: DatabaseFile) => {
            row.files.push(file);
            return this.downloadFile(file.id)
          }),
          finalize(() => {
            row['isLoadingLabelGeneration'] = false;
          }),
        )
        .subscribe({
          error: (error: HttpErrorResponse) => {
            switch (error?.error?.code?.internalCode) {
              case 1092:
                this.toastr.warning(this.translateService.instant('Orders.List.Columns.Label.For this provider labels cannot be created automatically'));
                break;
              case 1105:
                this.toastr.error(this.translateService.instant('Orders.List.Columns.Label.No label may be generated for an order that has already been canceled'));

              default:
                this.toastr.warning(error.error.message)
                break;
            }
          }
        })
    )
  }

  downloadLabels(): void {
    super.addSubscription(
      this.dialog
        .open(OrdersListDownloadLabelsDialogComponent, { data: { orders: this.selection.selected } })
        .afterClosed()
        .subscribe({
          next: () => {
            this.selection.clear();
            this.dataSource.reloadData();
          }
        })
    )
  }

  setDownloadActions(): void {
    this.downloadActions = [
      {
        displayName: 'Versandlabels herunterladen',
        isDisabled: () => this.selection.selected.length === 0 || (this.selection.selected.some(q => q.status === OrderStatus.Canceled) && !this.authenticationService.isSuperAdmin()),
        key: 'DownloadLabels'
      },
      {
        displayName: 'CSV Datei herunterladen',
        isDisabled: () => false,
        key: 'CSV'
      }
    ];

    if (this.authenticationService.isAnyAdministrator() && this.authenticationService.isSuperAdmin()) {
      this.downloadActions.push({
        displayName: 'CSV für Master-Datei herunterladen',
        isDisabled: () => false,
        key: 'MasterCSV'
      });
    }
  }

  doAction(): void {
    const key = this.selectedAction;

    switch (key) {
      case 'MasterCSV':
        this.exportOrders('Master');
        break;

      case 'CSV':
        this.exportOrders('Customer');
        break;

      case 'DownloadLabels':
        this.downloadLabels();
        break;

    }
  }

  downloadLabel(fileId: string, row?: TEntity): void {
    this.setShouldNavigate(false);

    if (!__.IsNullOrUndefined(row)) {
      row['isLoadingLabelDownload'] = true;
    }

    super.addSubscription(
      this.downloadFile(fileId)
        .pipe(
          finalize(() => {
            if (!__.IsNullOrUndefined(row)) {
              row['isLoadingLabelDownload'] = false;
              this.afterAction();
            }
          })
        )
        .subscribe()
    );
  }

  exportOrders(type: 'Master' | 'Customer'): void {
    this.isLoadingAction = true;

    const data = Object.assign(new OrderUpdateStatusOptions, {
      orderIds: this.selection.selected.map(q => q.id),
      type: type
    });

    super.addSubscription(
      this.filesService.createReport(
        `orders/export`,
        data,
      )
        .pipe(
          finalize(() => {
            this.afterAction();
            this.selection.clear();
          })
        )
        .subscribe({
          next: (data: { blob: Blob, name: string }) => {
            let { name } = data;
            if (!name.match('\.csv$')) {
              name += '.csv';
            }
            saveAs(data.blob, name);
          }
        })
    )
  }

  labelCanBeCreated(item: TEntity): boolean {
    return ((item.deliveryMethod as any) !== 'Selbstabholung' && item.status !== OrderStatus.Canceled) || this.authenticationService.isSuperAdmin();
  }

  openDeleteDialog(item: TEntity): void {
    item['isLoading'] = true;

    super.addSubscription(
      this.dialog.open(DialogComponent, {
        data: Object.assign(DialogComponent, {
          title: this.translateService.instant('Orders.Delete'),
          text: this.translateService.instant('Orders.Are you sure you permanently want to delete the order from the system?', { orderNumber: item.number }),
          maxHeight: '50vh',
          maxWidth: '30vw',
          submitButtonText: this.translateService.instant('General.Confirm'),
          cancelButtonText: this.translateService.instant('General.Cancel')
        })
      }).afterClosed()
        .pipe(
          switchMap((confirmed: boolean) => {
            if (confirmed) {
              return this.ordersService.deleteById(item.id)
            }
            return of(null);
          })
        ).subscribe({
          next: ((success: boolean) => {
            item['isLoading'] = false;

            if (!__.IsNullOrUndefinedOrEmpty(success)) {
              if (success) {
                this.toastr.success(this.translateService.instant('General.Orders.The order was deleted successfully'));
                this.dataSource.reloadData();
              } else {
                this.toastr.success(this.translateService.instant('General.Orders.The order could not be deleted'));
              }
            }
          }),
          error: (error: any) => {
            item['isLoading'] = false;

            switch (error.error.code.internalCode) {
              default:
                this.toastr.success(this.translateService.instant('General.Orders.The order could not be deleted'));
                break;
            }
          }
        })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // -----------------------------------------------------------------------------------------------------

  protected setFiltersForm(): void {
    this.filtersForm = this.fb.group({});
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private downloadFile(itemId: string): Observable<any> {
    return this.filesService.downloadFile(
      `files/${itemId}`
    ).pipe(
      map((data: { blob: Blob, name: string }) => {
        saveAs(data.blob, data.name);

        return data;
      })
    );
  }

  private getDefaultConfiguration(): DatabaseConfiguration {
    return Object.assign(new DatabaseConfiguration(), {
      resource: `orders`,
      endpoint: `orders`,
    });
  }

  private setDataSource(): void {
    this.dataSource = new BaseDataSource(
      new BaseDatabase<TEntity>(
        this.getDefaultConfiguration(),
        this.injector,
        this.paginator,
        this.sort,
        this.filterValuesChanged$,
        this.reflectFiltersInUrl
      ),
      true
    );
  }

  private setMenuItems(): void {
    this.menuItems = [
      Object.assign(new GenericMenuItem(), {
        actionType: 'Edit order',
        displayName: this.translateService.instant('Orders.Actions.Edit order'),
        visible: (item: TEntity) => true,
        execute: (item: TEntity) => this.navigateToEntityEdit(item)
      }),
      Object.assign(new GenericMenuItem(), {
        actionType: 'Download label',
        displayName: this.translateService.instant('Orders.Actions.Download label'),
        visible: (item: TEntity) => item.files.some(q => q.type === 'TrackingLabel'),
        execute: (item: TEntity) => {
          const label = item.files.find(q => q.type === 'TrackingLabel');
          this.downloadLabel(label.id, item);
        }
      }),
      Object.assign(new GenericMenuItem(), {
        actionType: 'Download label return',
        displayName: this.translateService.instant('Orders.Actions.Download label return'),
        visible: (item: TEntity) => item.files.some(q => q.type === 'TrackingLabelReturn'),
        execute: (item: TEntity) => {
          const label = item.files.find(q => q.type === 'TrackingLabel');
          this.downloadLabel(label.id, item);
        }
      }),
      Object.assign(new GenericMenuItem(), {
        actionType: 'Delete order',
        displayName: this.translateService.instant('Orders.Actions.Delete order'),
        visible: (item: TEntity) => this.authenticationService.isSuperAdmin(),
        execute: (item: TEntity) => this.openDeleteDialog(item)
      })
    ];
  }

  private afterAction(): void {
    this.selectedAction = null;
    this.isLoadingAction = false;
  }
}
