import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';
import { DialogModule } from '@angular/cdk/dialog';
import { ButtonsCancelModule } from '../../shared/components/buttons/cancel/buttons-cancel.module';
import { ButtonsCreateModule } from '../../shared/components/buttons/create/buttons-create.module';
import { ButtonsDefaultModule } from '../../shared/components/buttons/default/buttons-default.module';
import { ButtonsMenuModule } from '../../shared/components/buttons/menu/buttons-menu.module';
import { EntityFilterSearchModule } from '../../shared/components/entity-filters/search/entity-filter-search.module';
import { EntityListModule } from '../../shared/components/entity-list/entity-list.module';
import { GenericMenuModule } from '../../shared/components/generic-menu';
import { ImageModule } from '../../shared/components/image/image.module';
import { LoadingModule } from '../../shared/components/loading/loading.module';
import { OrderStatusIndicatorComponent } from '../../shared/components/order-status-indicator/order-status-indicator.component';
import { PageModule } from '../../shared/components/page/page.module';
import { SearchModule } from '../../shared/components/search';
import { DefaultDatePipeModule } from '../../shared/pipes/default-date.module';
import { FilesService } from '../../shared/services/files.service';
import { OrdersService } from '../../shared/services/orders.service';
import { OrdersListComponent } from './orders-list.component';
import { OrdersListDownloadLabelsDialogComponent } from './shared/download-labels-dialog/orders-list-download-labels-dialog.component';
import { ProgressModule } from '../../shared/components/files/progress/progress.module';

/**
 * This module represents the export module for the orders list component
 *
 * @export
 */
@NgModule({
  imports: [
    SharedModule,

    MatSortModule,
    MatDividerModule,
    RouterModule,
    MatTableModule,
    MatTooltipModule,

    MatButtonModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    DefaultDatePipeModule,
    MatTooltipModule,
    ImageModule,
    ProgressModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,

    SearchModule,
    DialogModule,

    GenericMenuModule,
    ButtonsMenuModule,
    ButtonsCreateModule,
    ButtonsDefaultModule,
    ButtonsCancelModule,

    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    OrdersListDownloadLabelsDialogComponent,

    PageModule,

    OrderStatusIndicatorComponent,

    LoadingModule,
    EntityFilterSearchModule,

    EntityListModule,
  ],
  declarations: [OrdersListComponent],
  providers: [CurrencyPipe, FilesService, OrdersService],
  exports: [OrdersListComponent]
})
export class OrdersListModule { }
