import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { EntityListFiltersContainerComponent } from './entity-list-filters-container.component';

@NgModule({
  imports: [
    CommonModule,

    MatProgressSpinnerModule
  ],
  declarations: [EntityListFiltersContainerComponent],
  exports: [EntityListFiltersContainerComponent]
})
export class EntityListFiltersContainerModule { }
