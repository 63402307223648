import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { EntityFilterModule } from '../entity-filter/entity-filter.module';
import { EntityFilterDateRangeComponent } from './entity-filter-date-range.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,

    EntityFilterModule
  ],
  declarations: [EntityFilterDateRangeComponent],
  exports: [EntityFilterDateRangeComponent]
})
export class EntityFilterDateRangeModule { }
