import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { EntityFilterModule } from '../entity-filter/entity-filter.module';
import { EntityFilterRangeComponent } from './entity-filter-range.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    EntityFilterModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [EntityFilterRangeComponent],
  exports: [EntityFilterRangeComponent]
})
export class EntityFilterRangeModule { }
