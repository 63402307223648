import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityFilterComponent } from './entity-filter.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EntityFilterHeaderModule } from './header/entity-filter-header.module';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatExpansionModule,
    EntityFilterHeaderModule
  ],
  declarations: [EntityFilterComponent],
  exports: [
    EntityFilterComponent,

    EntityFilterHeaderModule
  ]
})
export class EntityFilterModule { }
