<kb-page [embedded]="showTitle === false">

  <!-- HEADER -->
  <kb-page-header *ngIf="showTitle === true">

    <!-- TITLE -->
    <kb-page-title>
      {{ 'Orders.List.Title' | translate }}
    </kb-page-title>

    <kb-page-actions>
      <kb-buttons-create class="flex-initial" (click)="navigateToNewEntity()">
        {{ 'Orders.List.Create a new order' | translate }}
      </kb-buttons-create>
    </kb-page-actions>

  </kb-page-header>

  <!-- CONTENT -->
  <kb-page-content>

    <kb-entity-list [showFilters]="false">

      <!-- HEADER -->
      <kb-entity-list-header [selection]="selection" (tableSettingsClicked)="showTableSettingsDialog()"
        *ngIf="showHeader === true">

        <!-- COUNT -->
        <kb-entity-list-header-count>
          <div class="flex flex-col md:flex-row md:gap-32">

            <div class="flex-initial">
              <!-- SEARCH -->
              <kb-entity-filter-search [title]="'Orders.List.Type to search' | translate"
                [control]="searchFilterFormControl" (valueChanged)="startSearch($event)">
              </kb-entity-filter-search>
            </div>
            <div class="flex-initial flex flex-col md:flex-row items-center">
              <div class="flex items-center">
                <ng-container *ngIf="selection.hasValue(); else noEntitiesSelected">
                  {{ selection.selected.length }} {{ 'Orders.List.of' | translate }} {{ rowCount }} {{ rowCount === 1 ?
                  ('Orders.List.Order' | translate) :
                  ('Orders.List.Orders' | translate) }} {{ 'Orders.List.selected' | translate }}
                </ng-container>
                <ng-template #noEntitiesSelected>
                  {{ rowCount }} {{ rowCount === 1 ? ('Orders.List.Order' | translate) :
                  ('Orders.List.Orders' | translate) }}
                </ng-template>
              </div>
              <div class="ml-8 flex gap-4">

                <!-- ACTION-->
                <mat-form-field>
                  <mat-select [value]="selectedAction" (valueChange)="selectedAction = $event" placeholder="Aktion auswählen">
                    <mat-option style="padding-top: 6px; padding-bottom: 6px;" [disabled]="action.isDisabled()" *ngFor="let action of downloadActions" [value]="action.key">
                      {{ action.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <kb-buttons-default class="flex-initial mt-2" [isDisabled]="IsNullOrUndefinedOrEmpty(selectedAction)" [icon]="'arrow_simple_right'" [isLoading]="isLoadingAction"
                  (onClick)="doAction()">
                  Ausführen
                </kb-buttons-default>
              </div>
            </div>
          </div>
        </kb-entity-list-header-count>

        <!-- ACTIONS -->
        <kb-entity-list-header-actions>
          <ng-container *ngIf="showActions === true">
          </ng-container>
        </kb-entity-list-header-actions>

      </kb-entity-list-header>

      <!-- CONTENT -->
      <kb-entity-list-content-container [showFilters]="false">

        <!-- TABLE -->
        <kb-entity-list-table>
          <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" matSortActive="created"
            matSortDisableClear class="kb-table ">

            <!-- SELECTION -->
            <ng-container matColumnDef="select" sticky>
              <th mat-header-cell *matHeaderCellDef style="padding-right: 12px; width: 80px;">
                <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row; let i = index"
                style="padding-left: 0px; min-height: 52px; width: 80px;">
                <div class="flex" *ngIf="isLoading === false">
                  <div style="min-width: 9px;">
                    <kb-order-status-indicator [appearance]="'line'" [status]="row.status"></kb-order-status-indicator>
                  </div>
                  <mat-checkbox class="ml-2" style="margin-top: 6px;" color="primary" (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </div>
              </td>
            </ng-container>

            <!-- ORDER NUMBER -->
            <ng-container matColumnDef="number">
              <th class="column-heading column-heading--first" mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Order number' | translate }}
              </th>
              <td class="column column--first" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  #{{ row?.number }}
                </div>
              </td>
            </ng-container>

            <!-- RECIPIENT -->
            <ng-container matColumnDef="recipient">
              <th class="column-heading column-heading--first" mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Recipient' | translate }}
              </th>
              <td class="column column--first" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  {{ row?.recipient }}
                </div>
              </td>
            </ng-container>

            <!-- STATUS -->
            <ng-container matColumnDef="status">
              <th class="column-heading column-heading--first" style="max-width: 160px; width: 160px; min-width: 160px;"
                mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Status' | translate }}
              </th>
              <td class="column column--first" style="max-width: 160px; width: 160px; min-width: 160px;" mat-cell
                *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  <kb-order-status-indicator [appearance]="'chip'" [status]="row.status"></kb-order-status-indicator>
                </div>
              </td>
            </ng-container>

            <!-- RECEIVED AT -->
            <ng-container matColumnDef="created">
              <th class="column-heading column-heading--first" mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Received at' | translate }}
              </th>
              <td class="column column--first" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  {{ row?.created | defaultDate }}
                </div>
              </td>
            </ng-container>

            <!-- DELIVERY PERIOD -->
            <ng-container matColumnDef="deliveryPeriod">
              <th class="column-heading column-heading--first" style="max-width: 130px; width: 130px; min-width: 130px;"
                mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Delivery period' | translate }}
              </th>
              <td class="column column--first" style="max-width: 130px; width: 130px; min-width: 130px;" mat-cell
                *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  <div *ngIf="!IsNullOrUndefined(row?.slot)">
                    {{ row?.slot?.utcStartAt | date:'dd.MM.yyyy' }} {{ 'DatePipe.at' | translate }}
                    <br>
                    {{ row?.slot?.utcStartAt | date:'HH:mm' }} - {{ row?.slot?.utcEndAt | date:'HH:mm' }}
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- DELIVERY METHOD -->
            <ng-container matColumnDef="deliveryMethod">
              <th class="column-heading column-heading--first" mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Orders.List.Columns.Delivery method' | translate }}
              </th>
              <td class="column column--first" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  {{ row?.deliveryMethod }}
                </div>
              </td>
            </ng-container>

            <!-- ACTIONS -->
            <ng-container matColumnDef="labels">
              <th class="column-heading column-heading--actions" mat-header-cell *matHeaderCellDef>
                {{ 'Orders.List.Columns.Labels' | translate }}
              </th>
              <td class="column column--actions" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">

                  <!-- FILES EXIST -->
                  <ng-container *ngFor="let file of row.files; let last = last">
                    <ng-container [ngSwitch]="file.type">
                      <ng-container *ngSwitchCase="FileType.TrackingLabel">

                        <mat-spinner style="margin-left: 12px;" [diameter]="20"
                          *ngIf="row['isLoadingLabelDownload'] === true"></mat-spinner>

                        <!-- DOWNLOAD LABEL -->
                        <button type="button" mat-icon-button (click)="downloadLabel(file.id, row)"
                          *ngIf="row['isLoadingLabelDownload'] !== true" matTooltipPosition="left"
                          [matTooltip]="'Orders.List.Columns.Label.Download delivery label' | translate">
                          <mat-icon [svgIcon]="'delivery_label'"></mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <!-- NO FILES EXIST -->
                  <ng-container *ngIf="IsNullOrUndefined(row.files) || row.files.length === 0">
                    <mat-spinner style="margin-left: 12px;" [diameter]="20"
                      *ngIf="row['isLoadingLabelGeneration'] === true"></mat-spinner>

                    <!-- GENERATE LABEL -->
                    <button type="button" mat-icon-button *ngIf="row['isLoadingLabelGeneration'] !== true && labelCanBeCreated(row)"
                      (click)="generateLabel(row)" matTooltipPosition="left"
                      [matTooltip]="'Orders.List.Columns.Label.Generate delivery label' | translate">
                      <mat-icon style="opacity: 0.3" [svgIcon]="'delivery_label_unavailable'"></mat-icon>
                    </button>
                  </ng-container>
                </div>
              </td>
            </ng-container>

            <!-- ACTIONS -->
            <ng-container matColumnDef="actions" stickyEnd>
              <th class="column-heading column-heading--actions" mat-header-cell *matHeaderCellDef>
                {{ 'Orders.List.Columns.Actions' | translate }}
              </th>
              <td class="column column--actions" mat-cell *matCellDef="let row; let i = index">
                <div
                  *loading="isLoading; class: 'kb-table__row__column__title--loading--' + (90 - 15 * (i % 4)).toString()">
                  <kb-generic-menu (onMenuClicked)="setShouldNavigate(false)" [menuItems]="menuItems" [item]="row">
                  </kb-generic-menu>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="hover-row" [class.cursor-pointer]="shouldNavigate === true" (click)="navigateToEntity(row)"
              [class.selected]="selection.isSelected(row)" mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          </table>

          <!-- EMPTY QUERY -->
          <div *ngIf="rowCount === 0 && !isLoading">
            <div class="text-center py-6 px-4">
              {{ dataSource.database.filtersApplied ?
              ('Orders.List.Please change your search criteria' | translate) :
              ('Orders.List.There are no orders yet' | translate) }}
            </div>
          </div>
        </kb-entity-list-table>

      </kb-entity-list-content-container>

      <kb-entity-list-footer>
        <div class="flex justify-end">
          <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 40, 60, 80, 100]"></mat-paginator>
        </div>
      </kb-entity-list-footer>
    </kb-entity-list>

  </kb-page-content>

</kb-page>