import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DatabaseFile } from '../models/classes/File';

import { Order } from '../models/classes/orders/Order';
import { OrderCreateOptions } from '../models/classes/orders/OrderCreateOptions';
import { OrderStatus } from '../models/classes/orders/OrderStatus';
import { OrderUpdateOptions } from '../models/classes/orders/OrderUpdateOptions';
import { EntityService } from './entity.service';
import { Response } from './local/Response';
import { I18nService } from '../../core/i18n.service';
import { Logger } from '../../core/logger.service';

const log = new Logger('Users Service');

@Injectable()
export class OrdersService extends EntityService<Order, OrderCreateOptions, OrderUpdateOptions> {

  constructor(
    protected httpClient: HttpClient,
    protected i18nService: I18nService
  ) {
    super('orders', 'Order', httpClient, i18nService);
  }

  updateStatus(id: string, updateOptions: OrderUpdateStatusOptions): Observable<Order> {
    return this.httpClient
      .patch<Response<Order>>(`orders/${id}/status`, updateOptions)
      .pipe(
        map(response => response.data)
      );
  }

  createDeliveryLabel(id: string): Observable<DatabaseFile> {
    return this.createLabel(id, 'Delivery');
  }

  createDeliveryLabelReturn(id: string): Observable<DatabaseFile> {
    return this.createLabel(id, 'Return');
  }

  createLabel(id: string, type: 'Delivery' | 'Return'): Observable<DatabaseFile> {
    return this.httpClient
      .post<Response<DatabaseFile>>(`orders/${id}/labels?type=${type}`, null)
      .pipe(
        map(response => response.data)
      );
  }
}

export class OrderUpdateStatusOptions {
  status: OrderStatus;
  providerId: string;
}
