<div class="w-[40rem]">
    <kb-dialog-header
        [title]="'Labels herunterladen (' + data.orders.length + ' ' + (data.orders.length === 1 ? 'Bestellung' : 'Bestellungen') + ')'"
        class="orders-list-download-labels-dialog-header" [icon]="'address_card'"
        (emitCloseClick)="dialogRef.close(null)"></kb-dialog-header>

    <kb-dialog-content>

        <div>
            <p class="text-sm -mt-6 mb-5">Lade einzelne oder mehrere Labels herunter.</p>
            <table class="entity-list-table -ml-2">
                <tr>
                    <td class="column-heading pl-2">
                        Bestellnummer
                    </td>
                    <td class="column-heading">
                        Empfänger
                    </td>
                    <td class="column-heading">
                        Versandmethode
                    </td>
                    <td class="column-heading">
                        Labels
                    </td>
                </tr>
                <ng-container *ngFor="let order of data.orders; let i = index">

                    <tr [ngClass]="{ 'row--warning': order['dialogIsLabelGenerationError'] === true,
                    'row--success': order['dialogIsLabelGenerationSuccess'] === true,
                    'row--default': order['dialogIsLabelGenerationError'] === false && order['dialogIsLabelGenerationSuccess'] === false
                        }">
                        <!-- NUMBER -->
                        <td class="column pl-2">
                            #{{ order?.number }}
                        </td>

                        <!-- RECIPIENT -->
                        <td class="column">
                            {{ order?.recipient }}
                        </td>

                        <!-- DELIVERY METHOD -->
                        <td class="column">
                            {{ order?.deliveryMethod }}
                        </td>

                        <!-- LABELS -->
                        <td class="column w-10">

                            <div class="h-12 flex gap-4">
                                <div class="p-1 pt-1.5">
                                    <!-- FILES EXIST -->
                                    <ng-container *ngFor="let file of order.files; let last = last">
                                        <ng-container [ngSwitch]="file.type">
                                            <ng-container *ngSwitchCase="FileType.TrackingLabel">

                                                <mat-spinner style="margin-left: 4px; margin-top: 6px;" [diameter]="20"
                                                    *ngIf="order['dialogIsLoadingLabelDownload'] === true"></mat-spinner>

                                                <!-- DOWNLOAD LABEL -->
                                                <button type="button" class="label-button" mat-icon-button
                                                    (click)="downloadLabel(file.id, order)"
                                                    *ngIf="order['dialogIsLoadingLabelDownload'] !== true"
                                                    matTooltipPosition="left"
                                                    [matTooltip]="'Orders.List.Columns.Label.Download delivery label' | translate">
                                                    <mat-icon [svgIcon]="'delivery_label'"></mat-icon>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>

                                    <!-- NO FILES EXIST -->
                                    <ng-container *ngIf="IsNullOrUndefined(order.files) || order.files.length === 0">
                                        <mat-spinner style="margin-left: 4px; margin-top: 6px;" [diameter]="20"
                                            *ngIf="order['dialogIsLoadingLabelGeneration'] === true"></mat-spinner>

                                        <!-- GENERATE LABEL -->
                                        <button type="button" mat-icon-button class="label-button"
                                            *ngIf="order['dialogIsLoadingLabelGeneration'] !== true && labelCanBeCreated(order)"
                                            (click)="generateLabel(order)" matTooltipPosition="left"
                                            [matTooltip]="'Orders.List.Columns.Label.Generate delivery label' | translate">
                                            <mat-icon style="opacity: 0.3"
                                                [svgIcon]="'delivery_label_unavailable'"></mat-icon>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>

                        </td>
                    </tr>

                    <tr *ngIf="order['dialogIsLabelGenerationError'] === true"
                        class="bg-yellow-100 text-yellow-800 text-sm border-x border-b border-solid border-yellow-400">
                        <td colspan="4" class="px-2 pb-2">
                            <div class="-mt-1">
                                {{ order['dialogLabelGenerationError'] }}
                            </div>
                        </td>
                    </tr>

                </ng-container>
            </table>
        </div>

    </kb-dialog-content>

    <kb-dialog-footer>

        <kb-messages-success *ngIf="hasGenerated === true && labelsGeneratedAmount === data.orders.length">
            <div>
                Es wurden&nbsp;<strong>{{ labelsGeneratedAmount }} von {{ data.orders.length
                    }}</strong>&nbsp;Versandlabels
                erstellt und heruntergeladen.
            </div>

        </kb-messages-success>

        <kb-messages-warning *ngIf="hasGenerated === true && labelsGeneratedAmount !== data.orders.length">
            <div>
                Es wurden&nbsp;<strong>{{ labelsGeneratedAmount }} von {{ data.orders.length
                    }}</strong>&nbsp;Versandlabels
                erstellt
                und heruntergeladen. {{ labelsGeneratedErrorAmount }} {{ labelsGeneratedErrorAmount === 1 ? 'konnte' :
                'konnten' }}
                nicht erstellt und heruntergeladen werden.
            </div>
        </kb-messages-warning>

        <div class="flex flex-row place-content-end pt-2 gap-4 mt-2">
            <kb-buttons-default class="flex-initial" (onClick)="dialogRef.close(null)">
                Schließen
            </kb-buttons-default>

            <kb-buttons-submit *ngIf="hasGenerated === false" [colorClass]="'bg-primary-blue'" [displayText]="'Alle Versandlabels erstellen & herunterladen'"
                [isLoading]="isLoadingAction" (onClick)="downloadLabels()">
            </kb-buttons-submit>
        </div>
    </kb-dialog-footer>
</div>