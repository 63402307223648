import { Component, Input, OnInit } from '@angular/core';

import { BaseLoadingButton } from '../shared/base-button';

@Component({
  selector: 'kb-buttons-create',
  templateUrl: './buttons-create.component.html',
  styleUrls: ['./buttons-create.component.scss']
})
export class ButtonsCreateComponent extends BaseLoadingButton implements OnInit {

  @Input() routerLink: string;

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
