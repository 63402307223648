import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { EntityFilterSearchComponent } from './entity-filter-search.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatInputModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule
  ],
  declarations: [EntityFilterSearchComponent],
  exports: [EntityFilterSearchComponent]
})
export class EntityFilterSearchModule { }
