import { OrderStatus } from '../models/classes/orders/OrderStatus';
import { __ } from './object.functions';

export class OrderStatusHelper {
    static OrderStatuses = [
        // {
        //     value: OrderStatus.LabelDeleted,
        //     displayName: 'Orders.Status.LabelDeleted'
        // },
        {
            value: OrderStatus.LabelCreated,
            displayName: 'Orders.Status.LabelCreated'
        },
        // {
        //     value: OrderStatus.AwaitsFulfillment,
        //     displayName: 'Orders.Status.AwaitsFulfillment'
        // },
        {
            value: OrderStatus.Fulfillment,
            displayName: 'Orders.Status.Fulfillment'
        },
        // {
        //     value: OrderStatus.PickedUp,
        //     displayName: 'Orders.Status.PickedUp'
        // },
        {
            value: OrderStatus.Transit,
            displayName: 'Orders.Status.Transit'
        },
        // {
        //     value: OrderStatus.OutForDelivery,
        //     displayName: 'Orders.Status.OutForDelivery'
        // },
        {
            value: OrderStatus.Delivered,
            displayName: 'Orders.Status.Delivered'
        },
        // {
        //     value: OrderStatus.AwaitsPickupByReceiver,
        //     displayName: 'Orders.Status.AwaitsPickupByReceiver'
        // },
        {
            value: OrderStatus.Canceled,
            displayName: 'Orders.Status.Canceled'
        },
        // {
        //     value: OrderStatus.Delayed,
        //     displayName: 'Orders.Status.Delayed'
        // },
        // {
        //     value: OrderStatus.Exception,
        //     displayName: 'Orders.Status.Exception'
        // },
        // {
        //     value: OrderStatus.NotDelivered,
        //     displayName: 'Orders.Status.NotDelivered'
        // },
        // {
        //     value: OrderStatus.Destroyed,
        //     displayName: 'Orders.Status.Destroyed'
        // },
        // {
        //     value: OrderStatus.Notification,
        //     displayName: 'Orders.Status.Notification'
        // },
        // {
        //     value: OrderStatus.Unknown,
        //     displayName: 'Orders.Status.Unknown'
        // }
        {
            value: OrderStatus.ReturnTransit,
            displayName: 'Orders.Status.ReturnTransit'
        }
    ];

    static GetOrderStatuses()
    {
        return [
            // {
            //     value: OrderStatus.LabelDeleted,
            //     displayName: 'Orders.Status.LabelDeleted'
            // },
            {
                value: OrderStatus.LabelCreated,
                displayName: 'Orders.Status.LabelCreated'
            },
            // {
            //     value: OrderStatus.AwaitsFulfillment,
            //     displayName: 'Orders.Status.AwaitsFulfillment'
            // },
            {
                value: OrderStatus.Fulfillment,
                displayName: 'Orders.Status.Fulfillment'
            },
            // {
            //     value: OrderStatus.PickedUp,
            //     displayName: 'Orders.Status.PickedUp'
            // },
            {
                value: OrderStatus.Transit,
                displayName: 'Orders.Status.Transit'
            },
            // {
            //     value: OrderStatus.OutForDelivery,
            //     displayName: 'Orders.Status.OutForDelivery'
            // },
            {
                value: OrderStatus.Delivered,
                displayName: 'Orders.Status.Delivered'
            },
            // {
            //     value: OrderStatus.AwaitsPickupByReceiver,
            //     displayName: 'Orders.Status.AwaitsPickupByReceiver'
            // },
            {
                value: OrderStatus.Canceled,
                displayName: 'Orders.Status.Canceled'
            },
            // {
            //     value: OrderStatus.Delayed,
            //     displayName: 'Orders.Status.Delayed'
            // },
            // {
            //     value: OrderStatus.Exception,
            //     displayName: 'Orders.Status.Exception'
            // },
            // {
            //     value: OrderStatus.NotDelivered,
            //     displayName: 'Orders.Status.NotDelivered'
            // },
            // {
            //     value: OrderStatus.Destroyed,
            //     displayName: 'Orders.Status.Destroyed'
            // },
            // {
            //     value: OrderStatus.Notification,
            //     displayName: 'Orders.Status.Notification'
            // },
            // {
            //     value: OrderStatus.Unknown,
            //     displayName: 'Orders.Status.Unknown'
            // }
            {
                value: OrderStatus.ReturnTransit,
                displayName: 'Orders.Status.ReturnTransit'
            }
        ];
    }

    static GetOrderStatusByNameOrNull(displayName: string): any {
        const result = OrderStatusHelper.OrderStatuses.find((item: any) => item.displayName === displayName);
        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }
        return result;
    }

    static GetOrderStatusByValueOrNull(value: string): any {
        const result = OrderStatusHelper.OrderStatuses.find((currency: any) => currency.value === value);
        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }
        return result;
    }
}
