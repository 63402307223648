<kb-dialog-header title="{{'General.You have pending changes' | translate}}" class="dialog-header"
  (emitCloseClick)="dialogRef.close(null)">
</kb-dialog-header>

<div style="margin-top: 24px; min-width: 500px; margin-bottom: 24px; padding-left: 20px; padding-right: 20px;">
  {{'General.Are you sure to leave the page without saving?' | translate}}
</div>

<kb-dialog-footer>
  <div class="flex flex-row">
    <kb-buttons-default class="flex-initial" (onClick)="dialogRef.close(null)">{{'General.Cancel' | translate}}
    </kb-buttons-default>
    <kb-buttons-submit class="ml-1 flex-initial" (onClick)="dialogRef.close(true)">{{'General.Confirm' | translate}}
    </kb-buttons-submit>
  </div>

</kb-dialog-footer>