import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { OrderStatusHelper } from '@app/shared/functions/order-status-helper';
import { OrderStatus } from '@app/shared/models/classes/orders/OrderStatus';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kb-order-status-indicator',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './order-status-indicator.component.html'
})
export class OrderStatusIndicatorComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  color: string = '';

  statusName: string;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _status: OrderStatus;

  @Input()
  get status(): OrderStatus {
    return this._status;
  }
  set status(value: OrderStatus) {
    this._status = value;

    this.color = this.getColorByStatus(value);
    this.statusName = this.getNameByStatus(value);
  }

  @Input() appearance: 'line' | 'chip' = 'line';

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE METHODS
  // -----------------------------------------------------------------------------------------------------

  private getColorByStatus(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.Delivered:
        return '#15A860';

      case OrderStatus.Transit:
        return '#4371B7';

      case OrderStatus.Fulfillment:
        return '#B5FF00';

      case OrderStatus.LabelCreated:
        return '#F7AB46';

      case OrderStatus.ReturnTransit:
        return '#EA576F';

      // TODO: Add raisedReason cases

      default:
        return '#A10909'
    }
  }

  private getNameByStatus(status: OrderStatus): string {
    return OrderStatusHelper.GetOrderStatusByValueOrNull(status)?.displayName;
  }
}
