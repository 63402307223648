import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MessagesWarningComponent } from './messages-warning.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule
  ],
  declarations: [MessagesWarningComponent],
  exports: [MessagesWarningComponent]
})
export class MessagesWarningModule { }
