import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kb-entity-list-table',
  templateUrl: './entity-list-table.component.html',
  styleUrls: ['./entity-list-table.component.scss']
})
export class EntityListTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
